function bodyLoaded(callback) {
    if (!body.hasClass("body--loaded")) {
        let wait = setInterval(function(){
            if (body.hasClass("body--loaded")) {
                clearInterval(wait);
                callback();
            }
        },0);
    } else {
        callback();
    }
};