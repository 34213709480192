$.lui("part",".part_form_inquiry",function (selector) {
    let dp_el = selector.find("[data-datepicker]"),
        dp_input = dp_el.find("input").first(),
        dp_alt_from = dp_el.find("[data-datepicker-from]"),
        dp_alt_to = dp_el.find("[data-datepicker-to]"),
        select_model = selector.find("[data-model]");
    
    if(dp_el.length) {
        $.importStyle(cdnjs.daterangepicker_css);
        $.importScript(cdnjs.moment,function () {
            if(lang !== "en") {
                $.importScript(cdnjs.moment_lang.replace(".cs.", "."+lang+"."), function(){
                    moment.locale(lang);
                    initDatepicker();
                })
            }
            else {
                initDatepicker();
            }

        });

        select_model.on("change",function (e) {
            e.preventDefault();
            $.ajax({
                method: 'get',
                data: {'do': 'selectModel', 'id': $('[data-model]').val(), 'from': $('[data-datepicker-from]').val(), 'to': $('[data-datepicker-to]').val()},
                dataType: 'json'
            }).done(function (payload) {
                //console.log(payload);
                fn_ajaxHandler(payload, function () {
                    $('[data-heading]').show();
                    $('[data-totalprice]').text(payload.price);
                    $('[data-namemodel]').text(payload.name);
                    $('[date-infodate]').html(payload.date);
                    ScrollReveal().sync();
                });
            });
        });

        let initDatepicker = function () {
            $.importScript(cdnjs.daterangepicker,function () {
                dp_input.daterangepicker({
                    autoUpdateInput: false,
                    minDate: moment(new Date()).add(1,'days'),
                    autoApply: true,
                },function (start, end) {
                    dp_input.val(start.format("L") + " - " + end.format("L"));
                    dp_el.addClass("state--valid");
                    dp_alt_from.val(start.format("YYYY-MM-DD"));
                    dp_alt_to.val(end.format("YYYY-MM-DD"));

                    let days = end.diff(start,"days") + 1;
                    $.ajax({
                        method: 'get',
                        data: {'do': 'selectModel', 'id': $('[data-model]').val(), 'from': $('[data-datepicker-from]').val(), 'to': $('[data-datepicker-to]').val()},
                        dataType: 'json'
                    }).done(function (payload) {
                        fn_ajaxHandler(payload, function () {
                            $('[data-heading]').show();
                            $('[data-totalprice]').text(payload.price);
                            $('[data-namemodel]').text(payload.name);
                            $('[date-infodate]').html(payload.date);
                            ScrollReveal().sync();
                        });
                    });
                });
            });
        }
    }
});