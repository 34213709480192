$.lui("comp",".comp_carousel_products",function (selector) {
    let slider = selector.find("[data-lib-flickity]"),
        slides = slider.find(".elm_item");
    if(slider.find(".elm_item").length > 1){
        $.lib_flickity(function(){
            slider.on("ready.flickity",function () {
                slider.find(".flickity-prev-next-button").appendTo(selector.find("[data-lib-flickity-btns]"));
                if(slides.length < 4) {
                    slider.addClass("flickity--not-enough")
                }
            });

            slider.flickity({
                groupCells: false,
                cellAlign: "center",
                setGallerySize: true,
                autoPlay: false,
                pageDots: false,
                wrapAround: true,
                contain: true,
                prevNextButtons: true,
                initialIndex: '[data-initial-select]'
            });

            let flkty = slider.data("flickity");

            slider.on("settle.flickity",function(index){
                if(slider.is("[data-nav]")) {
                    let url = slider.closest('[data-ajax-link]').attr('data-ajax-link');
                    $.ajax({
                        url: url,
                        method: 'get',
                        data: {ajax: +new Date, 'do': 'rewritte', 'productId': $(flkty.selectedElement).data("id")},
                        dataType: 'json'
                    }).done(function (payload) {                        
                        fn_ajaxHandler(payload, function () {
                            $('[data-lib-tabs-item]').removeClass('state--active');
                            $('[data-lib-tabs-item]:first').addClass('state--active').trigger('click');
                            ScrollReveal().sync();
                        });
                    });
                }
            });
        })
    }

    cssLoaded(function () {
        slider.find(".elm_item").each(function () {
            let el = $(this),
                options = el.find(".elm_attributes").find(".col");

            fn_last_row(options);

            win.on("resize",debounce(function () {
                fn_last_row(options);
            },250));
        });

        function fn_last_row(options) {
            let top = options.first().position().top;
            options.each(function () {
                let col = $(this);
                col.removeClass("is--last");

                if(col.position().top > top) {
                    top = col.position().top;
                    col.prev(".col").addClass("is--last");
                }
            })
        }
    });
});