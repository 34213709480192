$.lui("comp",".comp_carousel_products_small",function (selector) {
    let slider = selector.find("[data-lib-flickity]");
    if(slider.find(".elm_item").length > 1){
        $.lib_flickity(function(){
            slider.flickity({
                groupCells: false,
                cellAlign: "left",
                setGallerySize: true,
                autoPlay: false,
                pageDots: false,
                wrapAround: true,
                contain: true,
                prevNextButtons: true,
            });
        })
    }
});