$.lui("part",".part_tabs",function (selector) {
    let nav = selector.find(".wrp_part_body");

    if(nav.length) {
        let inner = nav.find("ul"),
            inner_col = inner.find("li"),
            inner_end = Math.round(fn_col_width(inner_col.length));

        fn_nav_scroll();
        inner.on("scroll",fn_nav_scroll);
        win.on("resize",debounce(function () {
            fn_nav_scroll();
        },250));

        inner.scrollLeft(fn_col_width(nav.find(".state--active").parent().index()));

        function fn_col_width(index) {
            let width = 0;
            inner_col.each(function(i){
                if (i < index) {
                    width = width + $(this).outerWidth();
                }
            });
            return width;
        }

        function fn_nav_scroll() {
            nav.removeClass("is--state-end is--state-start");

            if (inner_end < win.width()) {
                nav.addClass("is--state-end");
            }
            if (Math.floor(parseInt(inner.scrollLeft()+inner.width())) === inner_end) {
                nav.addClass("is--state-end");
            }
            if (inner.scrollLeft() < 48) {
                nav.addClass("is--state-start");
            }
        }
    }
});