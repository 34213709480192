$.lui("comp",".comp_video",function (selector) {
    selector.off("click");
    let video = selector.find("video"),
        video_elm = video.get(0);

    selector.on("click",function () {
        if(video_elm.paused) {
            video_elm.play();
            video.attr("controls","controls");
            selector.addClass("state--playing");
        }
        else {
            video_elm.pause();
            video.removeAttr("controls");
            selector.removeClass("state--playing");
        }
    });
    video.on("ended",function () {
        selector.removeClass("state--playing");
        video.removeAttr("controls");
    });
});