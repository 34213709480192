let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.9.4/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.1.0/js/newlogic-cookieconsent.min.js",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.2.0/dist/flickity.pkgd.min.js",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=APIKEY",
    "lightgallery": "https://cdn.jsdelivr.net/npm/lightgallery@1.6.11/dist/js/lightgallery-all.min.js",
    // "datepicker": "https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/datepicker.min.js",
    // "datepicker_lang": "https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker.en.min.js",
    // "datepicker_css": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/css/datepicker.min.css",
    "daterangepicker": "https://cdn.jsdelivr.net/npm/daterangepicker@3.0.5/daterangepicker.min.js",
    "daterangepicker_css": "https://cdn.jsdelivr.net/npm/daterangepicker@3.0.5/daterangepicker.css",
    "moment": "https://cdn.jsdelivr.net/npm/moment@2.24.0/moment.min.js",
    "moment_lang": "https://cdn.jsdelivr.net/npm/moment@2.24.0/locale/cs.js",
    "recapcha": "https://www.google.com/recaptcha/api.js?render={apikey}"
};